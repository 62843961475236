export function isInternetExplorer(): boolean {
  const { userAgent } = window.navigator;

  return ['MSIE', 'Trident/', 'Edge/']
    .some(item => userAgent.indexOf(item) !== -1);
}

export function isMobileSafari(): boolean {
  const { userAgent } = window.navigator;

  return /iP(ad|hone|od).+Version\/[\d.]+.*Safari/i.test(userAgent);
}