import { JtagService } from "@services/jtag-service";

export default class AFSClickTracker {
  private static isOverIFrame = false;
  private static isFocusAcquired = false;
  private static jrConfigGlobal: JrConfigGlobal = window.jrConfigGlobal;
  private static dataLayer: any = window.dataLayer;

  private static handleFocus = (): void => {
    if (!AFSClickTracker.isFocusAcquired) {
      (<HTMLElement>document.activeElement).blur();
      window.focus();
      AFSClickTracker.isFocusAcquired = true;
    }
  }

  private static onMouseOut = (): void => {
    AFSClickTracker.handleFocus();
    AFSClickTracker.isOverIFrame = false;
  }

  private static onMouseOver = (): void => {
    AFSClickTracker.isOverIFrame = true;
  }

  private static onIFrameClick = (): void => {
    if (AFSClickTracker.isOverIFrame) {
      setTimeout(() => {
        AFSClickTracker.onClick();
      });
    }
  }

  private static getDevice = (): string => {
    if (AFSClickTracker.jrConfigGlobal.isMobile) {
      return 'HighEndMobile';
    }
    if (AFSClickTracker.jrConfigGlobal.isDesktop) {
      return 'Desktop';
    }
    if (AFSClickTracker.jrConfigGlobal.isTablet) {
      return 'Tablet';
    }
    return '';
  }

/*   private static getSource = (): string => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const source = urlParams.get('utm_source') || 'no source';

    return source;

  }

 */  private static onAFSClick = (type: string): void => {
    JtagService.trackCustomEvent('afs_clicks', {
      event_label: type,
      gclid: AFSClickTracker.jrConfigGlobal.user.gclid,
      msclkid: AFSClickTracker.jrConfigGlobal.user.msclkid,
      sessionSource: AFSClickTracker.dataLayer[0]?.visitSource || 'no source',
      jobseekerId: AFSClickTracker.jrConfigGlobal.user.uuid,
      country: AFSClickTracker.jrConfigGlobal.localeCountry,
      device: AFSClickTracker.getDevice()
    });
    console.log('visit source', AFSClickTracker.dataLayer[0]?.visitSource || 'no source')
    AFSClickTracker.handleFocus();
  }

  private static onClick = (): void => {
    const parentElement = document.activeElement?.parentElement;
    const parentElementId = parentElement?.id;
    const isMobileSerpAdsense = parentElement?.className.includes('adsense-serp-item');

    switch (parentElementId) {
      case 'atf-iframe-container':
        AFSClickTracker.onAFSClick('atf');
        break;

      case 'btf-iframe-container':
        AFSClickTracker.onAFSClick('btf');
        break;

      case 'jpp-iframe-container':
        AFSClickTracker.onAFSClick('jpp');
        break;

      default:
        AFSClickTracker.handleFocus();
        break;
    }

    if (isMobileSerpAdsense) {
      AFSClickTracker.onAFSClick('mobile_serp');
    }
  }

  private static doTrack = (): void => {
    window.addEventListener('blur', () => {
      setTimeout(() => {
        if (document.activeElement instanceof HTMLIFrameElement) {
          document.activeElement.onmouseover = AFSClickTracker.onMouseOver;
          document.activeElement.onmouseout = AFSClickTracker.onMouseOut;
          AFSClickTracker.onMouseOver();
          AFSClickTracker.onIFrameClick();
        }
      });
      AFSClickTracker.isFocusAcquired = false;
    }, false);
  }

  public static Track = (): void => {
    if (typeof window.addEventListener !== 'undefined') {
      window.addEventListener('DOMContentLoaded', AFSClickTracker.doTrack, false);
    }
  }
}