import { html, TemplateResult } from "lit/html.js";
import { ErrorModalTemplate } from "@components/commonTemplate";
import CloseButtonTemplate from "@libs/modal/closeButton";

const SocialSubscriptionErrorModalTemplate = (props: IErrorModalTemplateProps): TemplateResult => {
  const {
    messages,
    close
  } = props;

  return html`
    <div class="proposal">
      ${CloseButtonTemplate(close)}
      ${ErrorModalTemplate({ messages, close, socialSubscriptionError: true })}
    </div>
    `;
}

export default SocialSubscriptionErrorModalTemplate;