export default class GoogleOneTapLoader {
  private static jrConfigGlobal: JrConfigGlobal = window.jrConfigGlobal;

  public static load = (): void => {
    window.onGoogleLibraryLoad = async (): Promise<void> => {
      const {
        user: { mail = '', isNotConfirmed = true } = {},
        googleOneTap: { context = 'signin' } = {},
      } = GoogleOneTapLoader.jrConfigGlobal;

      if (!mail && isNotConfirmed) {
        const { default: GoogleOneTapService } = await import("./google-one-tap-service");
        GoogleOneTapService.initialize({
          client_id: '407919319839-ml2fku1eo2ubn56k8vf83kqpv910li6b.apps.googleusercontent.com',
          cancel_on_tap_outside: false,
          prompt_parent_id: 'jr-one-tap-wrapper',
          callback: GoogleOneTapService.handleResponse,
          context,
        });

        GoogleOneTapService.oneTapPrompt();
      }
    };
  }
}