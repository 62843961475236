import { loadAutoSubscriptionModal } from "@components/modal/subscriptionProposal";
import { renderSerpFiltersComponent } from "@components/serpFilters/config";
import AFSClickTracker from "@libs/afs-click-tracker";
import { createCookie, readCookie } from "@utils/cookie";
import GoogleOneTapLoader from "./google-one-tap-loader";
import { pushEvent } from "./gtm-service";
import { JtagService } from "./jtag-service";
import { activatePushnami } from "./notification-service";
import { getQueryParamFromUrl, getQueryStringParams } from "./referrer-service";
import { SidebarScrollService } from './sidebar-banners-service';
import TipsService from "./tips-service";

export default class PageLoaderService {
  private static jrConfigGlobal: JrConfigGlobal = window.jrConfigGlobal;

  public static init = async (): Promise<void> => {
    const { page: { isSearch = false, isJobPreviewPage = false } } = PageLoaderService.jrConfigGlobal;

    await PageLoaderService.loadCommons();

    if (isSearch) {
      await PageLoaderService.loadSerpAndJppCommons();
      await PageLoaderService.loadSerp();
    }

    if (isJobPreviewPage) {
      await PageLoaderService.loadSerpAndJppCommons();
    }
  }

  private static async loadCommons() {
    const { hardLogin = false, gdprAccepted = false } = PageLoaderService.jrConfigGlobal;

    if (hardLogin && !gdprAccepted) {
      const { loadGdprModal } = await import("@components/modal/gdpr/loader");
      loadGdprModal();
    }
    pushEvent({ event: 'addingcorrelationid', correlationId: this.jrConfigGlobal.correlationId })
  }

  private static async loadSerp() {
    let jtagPvidUrl = JtagService.getPvid();

    const {
      feature: { notificationAlertBoxEnabled = false },
      user: { mail = '', subscribedEmail = '' },
      showActivateProfileConfirmDialog = false,
      visit: { requestComingFromWelcomeEmailAddPassword = false, isOneTapConfirmationFlow = false },
      onboardingTooltipEnabled = false
    } = PageLoaderService.jrConfigGlobal;

    if (notificationAlertBoxEnabled) {
      const { default: NotificationAlert } = await import("@components/notificationAlert");
      NotificationAlert.init();
    }

    if (showActivateProfileConfirmDialog || requestComingFromWelcomeEmailAddPassword) {
      pushEvent({ event: 'jobseekerConfirm' });

      if (isOneTapConfirmationFlow) {
        const jobseekerEmail = mail || subscribedEmail;
        const { loadWelcomeOrConfirmationReminderModal } = await import("@components/modal/welcomeOrConfirmationReminder/loader");
        loadWelcomeOrConfirmationReminderModal({
          emailAddress: jobseekerEmail,
          source: 'google_one_tap',
        });
      } else {
        const { loadUpdatePasswordModal } = await import("@components/modal/updatePassword/loader");
        loadUpdatePasswordModal();
      }

      JtagService.trackCustomEvent('impression_confirmation_popup');
    }

    if (onboardingTooltipEnabled) {
      const { default: OnboardingTooltipComponent } = await import("@components/onBoardingTooltip");
      new OnboardingTooltipComponent().init();
    }

    TipsService.init();

    if (document.getElementById('tms-widget')) {
      const { default: TmsWidget } = await import("@components/tmsWidget");
      new TmsWidget().render();
    }

    if (!this.jrConfigGlobal.isMobile && !this.jrConfigGlobal.page.isErrorPage && this.jrConfigGlobal.search.sortByEnabled) {
      renderSerpFiltersComponent();
    }

    if (this.jrConfigGlobal.allJobsNavigationEnabled) {
      import('@components/newAllFilters');
    }

    const interval = setInterval(() => {
      if (jtagPvidUrl) {
        const pvid = getQueryParamFromUrl('pvid', jtagPvidUrl);

        createCookie('serp_pvid', pvid, { domain: 'jobrapido.com' });

        clearInterval(interval);
      } else {
        jtagPvidUrl = JtagService.getPvid();
      }
    }, 1000);
  }

  private static async loadSerpAndJppCommons() {
    const {
      canShowSubscriptionPopup = false,
      feature: { singleOptInWelcomePopupEnabled = false },
      user: { isJobSeeker = false, },
      modal: { confirmReminderMaxTimes = 0 },
      registeredJobseeker: { confirmed: jobseekerIsConfirmed = false, email: jobseekerEmail = '' }
    } = PageLoaderService.jrConfigGlobal;

    loadAutoSubscriptionModal();

    if (singleOptInWelcomePopupEnabled) {
      const queryParams = getQueryStringParams(window.location.search);

      if (queryParams.email) {
        const { loadWelcomeOrConfirmationReminderModal } = await import("@components/modal/welcomeOrConfirmationReminder/loader");

        loadWelcomeOrConfirmationReminderModal({
          emailAddress: queryParams.email,
          source: 'welcome'
        });
      }
    }

    AFSClickTracker.Track();

    GoogleOneTapLoader.load();

    if (isJobSeeker && !canShowSubscriptionPopup) {
      activatePushnami();
    }

    if (!jobseekerIsConfirmed && jobseekerEmail) {
      const cookieName = 'confirmReminderModalCount';

      const popupMaxCount = confirmReminderMaxTimes;
      const count = readCookie(cookieName) || '0';
      if (parseInt(count, 10) < popupMaxCount) {
        const { loadWelcomeOrConfirmationReminderModal } = await import("@components/modal/welcomeOrConfirmationReminder/loader");

        loadWelcomeOrConfirmationReminderModal({ emailAddress: jobseekerEmail, source: 'confirmation_reminder' })
      }
    }
    window.addEventListener('load', () => {
      SidebarScrollService.init();
    });
  }
}