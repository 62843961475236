import faqFormTemplate from '../../../view/angular/pages/faq-form.tpl.html';

angular.module('jrSupport').directive('faqForm', [
  '$rootScope',
  'SearchConfig',
  function ($rootScope, SearchConfig) {
    return {
      restrict: 'AE',
      scope: {
        faqValue: '@',
        faqFormLabel: '@',
        name: '@',
        changeTopic: '&onChange',
        ngModel: '=',
        isRequired: '=',
      },
      replace: true,
      template: faqFormTemplate,
      link(scope, element, attrs) {
        if (SearchConfig.isSubscriptionCaptchaEnabled) {
          element.ready(() => {
            $rootScope.supportPageCaptcha = grecaptcha.render('support-captcha-container', {
              sitekey: jrConfigGlobal.captcha.siteKey,
            });
          });
        }
      },
    };
  },
]);
