import { JtagService } from '../../ts/service/jtag-service.ts';
import { updateProfile } from '../../ts/service/xhr-service.ts';
import { footerCheckFixable } from '../../ts/utils/index.ts';

angular.module('jrMyProfile', ['jrConfig']).controller('MyProfileCtrl', [
  '$scope',
  '$rootScope',
  'MyProfileConfig',
  function ($scope, $rootScope, MyProfileConfig) {
    $scope.hasBeenSubmitted = false;
    $scope.forms = {};
    $scope.firstName = MyProfileConfig.firstname || '';
    $scope.lastName = MyProfileConfig.lastname || '';
    $scope.gdprJobMarketingAccepted = !MyProfileConfig.gdprJobMarketingAccepted;

    $scope.checkEmptyPasswordFields = function () {
      const profileForm = $scope.forms.profile;

      return (
        (profileForm.oldPassword &&
          !profileForm.oldPassword.$isEmpty(profileForm.oldPassword.$viewValue)) ||
        !profileForm.password.$isEmpty(profileForm.password.$viewValue) ||
        !profileForm.confirmPassword.$isEmpty(profileForm.confirmPassword.$viewValue)
      );
    };
    const handleUpdateProfileError = () => {
      $scope.hasBeenSubmitted = false;
      $scope.myProfileError = true;
      $scope.loading = false;
    };

    $scope.updateProfile = async function (e) {
      e.preventDefault();

      $scope.hasBeenSubmitted = true;
      if ($scope.forms.profile.$invalid || $scope.forms.profile.$pristine) {
        return;
      }

      const myProfileData = {
        firstName: $scope.forms.profile.firstname.$viewValue
          ? $scope.forms.profile.firstname.$viewValue
          : '',
        lastName: $scope.forms.profile.lastname.$viewValue
          ? $scope.forms.profile.lastname.$viewValue
          : '',
        password: $scope.forms.profile.password.$viewValue
          ? $scope.forms.profile.password.$viewValue
          : '',
        confirmPassword: $scope.forms.profile.confirmPassword.$viewValue
          ? $scope.forms.profile.confirmPassword.$viewValue
          : '',
        oldPassword:
          $scope.forms.profile.oldPassword && !!$scope.forms.profile.oldPassword.$viewValue
            ? $scope.forms.profile.oldPassword.$viewValue
            : '',
        gdprJobMarketingAccepted: !$scope.forms.profile.gdprjobmarketingaccepted.$viewValue,
      };

      $scope.loading = true;

      try {
        const response = await updateProfile(myProfileData);

        if (response.status >= 200 && response.status < 300) {
          $scope.successUpdateProfile = true;
          $scope.loading = false;
          $rootScope.jobseekerFirstname = $scope.forms.profile.firstname.$viewValue;
          $rootScope.jobseekerLastname = $scope.forms.profile.lastname.$viewValue;

          if (
            $scope.forms.profile.firstname.$viewValue !== $scope.firstName ||
            $scope.forms.profile.lastname.$viewValue !== $scope.lastName
          ) {
            JtagService.trackCustomEvent('click_my_profile', {
              event_label: $scope.forms.profile.password.$viewValue
                ? 'change_profile_password'
                : 'change_profile',
            });
          } else {
            JtagService.trackCustomEvent('click_my_profile', {
              event_label: 'change_password',
            });
          }

          if (
            $scope.forms.profile.gdprjobmarketingaccepted.$viewValue !==
            MyProfileConfig.gdprJobMarketingAccepted
          ) {
            JtagService.trackMarketingJobsPolicyClient(
              $scope.forms.profile.gdprjobmarketingaccepted.$viewValue ? 'optout' : 'optin'
            );
          }
        } else {
          handleUpdateProfileError();
        }
      } catch (e) {
        handleUpdateProfileError();
      }

      $scope.$apply();
      footerCheckFixable();
    };
  },
]);
