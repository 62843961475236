/* Faq Controller */

angular
  .module('jrSupport')
  .controller('SupportModalCtrl', [
    '$scope',
    '$modalInstance',
    function ($scope, $modalInstance) {
      $scope.ok = function () {
        $modalInstance.close();
      };
    },
  ])
  .controller('SupportFormCtrl', [
    '$scope',
    '$http',
    '$timeout',
    '$modal',
    'JobseekerConfig',
    'Messages',
    function ($scope, $http, $timeout, $modal, JobseekerConfig, Messages) {
      $scope.hasBeenSubmitted = false;
      $scope.buttonSubmitClicked = false;
      $scope.hasTextArea = true;
      $scope.text = '';
      $scope.topic = undefined;
      $scope.jobseeker = {
        email: JobseekerConfig.email,
      };

      $scope.messages = Messages;
      $scope.captchaUserResponseOk = true;

      const res = $scope.messages['CRM.SupportFormCategories'].split(',');

      $scope.categories = [];

      for (let i = 0; i < res.length; i += 1) {
        if ($scope.messages[res[i]] !== '') {
          $scope.categories.push({
            label: $scope.messages[res[i]],
            value: res[i],
            hasTextArea: res[i] !== 'CRM.CategoryUnsuscribe',
          });
        }
      }

      $scope.category = {
        selected: undefined,
      };

      const unsuscribeReasonProps = $scope.messages['CRM.UnsuscribeReason'].split(',');

      $scope.unsuscribeReasons = [];

      for (let i = 0; i < unsuscribeReasonProps.length; i += 1) {
        $scope.unsuscribeReasons[i] = {
          label: unsuscribeReasonProps[i],
          value: unsuscribeReasonProps[i],
        };
      }

      $scope.reason = {
        selected: undefined,
      };

      $scope.open = function () {
        $modal.open({
          template: `<div class="modal-header"><h3 class="modal-title">${
            $scope.category.selected.value === 'CRM.CategoryUnsuscribe'
              ? $scope.messages['CRM.SupportModalUnsubscribeTitle']
              : $scope.messages['CRM.SupportModalTitle']
          }</h3>
        </div>
        <div class="modal-body">${
          $scope.category.selected.value === 'CRM.CategoryUnsuscribe'
            ? $scope.messages['CRM.SupportModalUnsubscribeBody']
            : $scope.messages['CRM.SupportModalBody']
        }</div>
        <div class="modal-footer">
          <button class="jr-button" ng-click="ok()">${
            $scope.messages['CRM.SupportOkModalButton']
          }</button>
        </div>`,
          controller: 'SupportModalCtrl',
        });
      };

      $scope.openModalError = function () {
        $modal.open({
          template: `<div class="modal-header">\
                      <h3 class="modal-title">${
                        $scope.category.selected.value === 'CRM.CategoryUnsuscribe'
                          ? $scope.messages['CRM.SupportModalUnsubscribeErrorTitle']
                          : $scope.messages['CRM.SupportModalErrorTitle']
                      }</h3>\
                    </div>\
                    <div class="modal-body">${
                      $scope.category.selected.value === 'CRM.CategoryUnsuscribe'
                        ? $scope.messages['CRM.SupportModalUnsubscribeErrorBody']
                        : $scope.messages['CRM.SupportModalErrorBody']
                    }</div>\
                    <div class="modal-footer">\
                      <button class="jr-button" ng-click="ok()">${
                        $scope.messages['CRM.SupportOkModalButton']
                      }</button>\
                    </div>`,

          controller: 'SupportModalCtrl',
        });
      };

      $scope.changeTopic = function (hasTextArea) {
        $scope.secondStep = true;
        $scope.hasTextArea = hasTextArea;
        $scope.footerCheckFixable();
      };

      $scope.goBack = function () {
        $scope.buttonSubmitClicked = false;
        $scope.hasBeenSubmitted = false;
        $scope.secondStep = false;
        $scope.text = '';
        $scope.reason.selected = undefined;
        $scope.category.selected = undefined;

        grecaptcha.reset();
        $scope.captchaUserResponseOk = true;

        $scope.footerCheckFixable();
      };

      $scope.verifyCaptchaUserResponse = function () {
        const captchaUserResponse = grecaptcha.getResponse();
        $scope.captchaUserResponseOk =
          captchaUserResponse !== undefined && captchaUserResponse.length !== 0;
        $scope.$apply();
      };

      $scope.submit = function (isValid) {
        $scope.buttonSubmitClicked = true;
        $scope.hasBeenSubmitted = true;
        const captchaUserResponse = grecaptcha.getResponse();

        $scope.captchaUserResponseOk =
          captchaUserResponse !== undefined && captchaUserResponse.length !== 0;

        if (!isValid || $scope.captchaUserResponseOk === false) {
          $scope.buttonSubmitClicked = false;
          return;
        }

        if (isValid && captchaUserResponse.length !== 0) {
          let msg = angular.isUndefined($scope.reason.selected) ? '' : $scope.reason.selected;
          msg += angular.isUndefined($scope.text) ? '' : $scope.text;

          $http
            .get(
              `/${$scope.messages['crm.support_submit_action']}?captchaUserResponse=${captchaUserResponse}&topic=${$scope.category.selected.value}&message=${msg}&email=${$scope.jobseeker.email}`
            )
            .success(() => {
              grecaptcha.reset();

              $scope.open();
            })
            .error((data, status, headers) => {
              grecaptcha.reset();

              let captchaErrors = headers('captha_errors');
              if (captchaErrors === null) {
                captchaErrors = '';
              }

              let faqSubmitErrors = headers(
                $scope.messages['crm.support_submit_errors_header_key']
              );
              if (faqSubmitErrors === null) {
                faqSubmitErrors = '';
              }

              $scope.openModalError();
            });
        }
      };

      $scope.footerCheckFixable = function () {
        $timeout(() => {
          const footer = document.getElementById('footer');
          footer.classList.remove('fixed');
          if (angular.element(window)[0].innerHeight > footer.offsetTop + footer.offsetHeight) {
            footer.classList.add('fixed');
          }
        }, 351);
      };

      if (!jrConfigGlobal.isMobile) {
        $scope.footerCheckFixable();
      }
    },
  ]);
