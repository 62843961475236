import reactivate from './jobseekerReactivate';

try {
  const unsubcribeForm = document.querySelector('.js-unsubscribe-form');
  const unsubcribeCaptchaContainer = document.getElementById('unsubscribe-captcha-container');

  const handleCaptchaError = (captchaInstance) => {
    window.grecaptcha.reset(captchaInstance);
    document.querySelector('.js-unsubscribe-submit-btn').classList.remove('jr-button--loading');
  };
  if (unsubcribeForm) {
    const unsubscribeInfo = JSON.parse(unsubcribeForm.dataset.unsubscribeInfo);

    let captchaInstance = -1;

    if (jrConfigGlobal.captcha.enabled && unsubcribeCaptchaContainer) {
      const interval = setInterval(() => {
        if (window.grecaptcha) {
          grecaptcha.ready(() => {
            captchaInstance = grecaptcha.render('unsubscribe-captcha-container', {
              sitekey: jrConfigGlobal.captcha.siteKey,
              size: 'invisible',
              badge: 'inline',
              callback: (token) => {
                unsubscribeInfo.captchaResponse = token;
                reactivate(unsubscribeInfo || undefined);
              },
              'error-callback': handleCaptchaError,
              'expired-callback': handleCaptchaError,
            });
          });
          clearInterval(interval);
        }
      }, 100);
    }

    unsubcribeForm.addEventListener('submit', (event) => {
      event.preventDefault();

      document.querySelector('.js-unsubscribe-submit-btn').classList.add('jr-button--loading');

      if (jrConfigGlobal.captcha.enabled) {
        grecaptcha.ready(() => {
          grecaptcha.execute(captchaInstance);
        });
      } else {
        reactivate(unsubscribeInfo || undefined);
      }
    });
  }
} catch (e) {
  console.error(e);
}
