angular
  .module('jrConfig', [])
  .value('jrGlobalConfig', {
    baseUrl: jrConfigGlobal.baseUrl,
    pageUUID: jrConfigGlobal.page.uuid,
    cdnBaseUrl: jrConfigGlobal.cdnBaseUrl,
    infoPath: jrConfigGlobal.infoPath,
    localeLanguage: jrConfigGlobal.localeLanguage,
    userIp: jrConfigGlobal.website.userIp,
  })
  .value('Messages', jrConfigGlobal.messages)
  .value('SearchConfig', {
    what: jrConfigGlobal.search.what.term || '',
    where: jrConfigGlobal.search.where.term || '',
    rewrittenWhere: jrConfigGlobal.search.where.rewritten,
    rewrittenWhat: jrConfigGlobal.search.what.rewritten,
    computedWhere: jrConfigGlobal.search.where.computed,
    computedWhat: jrConfigGlobal.search.what.computed,
    whereByIp: jrConfigGlobal.search.where.resolvedByIp,
    isEmptySearch: !jrConfigGlobal.search.what.rewritten && !jrConfigGlobal.search.where.rewritten,
    isEmptyComputedSearch:
      !jrConfigGlobal.search.what.computed && !jrConfigGlobal.search.where.computed,
    country: jrConfigGlobal.localeCountry,
    radius: {
      value:
        jrConfigGlobal.search.radius.autoexpanded === 'true'
          ? 'auto'
          : jrConfigGlobal.search.radius.value,
      autoexpanded: jrConfigGlobal.search.radius.autoexpanded === 'true',
      unit: jrConfigGlobal.search.radius.unit,
    },
    isSubscriptionCaptchaEnabled: jrConfigGlobal.captcha.enabled,
    sortBy: jrConfigGlobal.search.sortBy,
    totalResults: jrConfigGlobal.search.totalResults,
    geoCoordinatesNotPresent: jrConfigGlobal.search.geoCoordinatesNotPresent,
    locationFieldInModalPopupEnabled: jrConfigGlobal.feature.locationFieldInModalPopupEnabled,
    whatFieldInModalPopupEnabled: jrConfigGlobal.feature.whatFieldInModalPopupEnabled,
  })
  .value('SearchFormOptionsConfig', jrConfigGlobal.searchForm || undefined)
  .value('SearchDistanceConfig', {
    enabled: jrConfigGlobal.feature.distanceFilterEnabled,
    autoDistanceText: 'auto',
    measure: jrConfigGlobal.milesCountries.indexOf(jrConfigGlobal.localeCountry) > -1 ? 'mi' : 'km',
    distances:
      jrConfigGlobal.milesCountries.indexOf(jrConfigGlobal.localeCountry) > -1
        ? jrConfigGlobal.distanceInMiles
        : jrConfigGlobal.distanceInKilom,
  })
  .value('SearchResultsConfig', jrConfigGlobal.searchResults)
  .value('JobseekerConfig', {
    id: jrConfigGlobal.user.uuid,
    email: jrConfigGlobal.user.subscribedEmail || jrConfigGlobal.user.mail || undefined,
    parentShardId: jrConfigGlobal.jobletter.jobseeker.parentShardId,
    parentUserId: jrConfigGlobal.jobletter.jobseeker.parentUserId,
    isLogged: jrConfigGlobal.user.isJobSeeker,
    isNotConfirmed: jrConfigGlobal.user.isNotConfirmed,
    registrationConfirmed: jrConfigGlobal.jobletter.jobseeker.registrationConfirmed,
    hardLogin: jrConfigGlobal.hardLogin,
    weakLogin: jrConfigGlobal.weakLogin,
  })
  .value('AutocompleteEmailConfig', {
    enabled: jrConfigGlobal.autocompleteEmail.enabled !== false,
    providersList: jrConfigGlobal.autocompleteEmail.providersList,
    providersLimit: 5,
    emailDomainMinLength: 1,
  })
  .value('VisitConfig', {
    uuid: jrConfigGlobal.visit.uuid,
    isOneTapConfirmationFlow: jrConfigGlobal.visit.isOneTapConfirmationFlow,
    requestComingFromWelcomeEmailAddPassword:
      jrConfigGlobal.visit.requestComingFromWelcomeEmailAddPassword,
    requestComingFromWelcomeEmailIncreaseSearch:
      jrConfigGlobal.visit.requestComingFromWelcomeEmailIncreaseSearch,
  })
  .value('MySearchesConfig', jrConfigGlobal.mySearches)
  .value('FavouriteJobsConfig', jrConfigGlobal.favouriteJobs)
  .value('PagesConfig', {
    isHome: jrConfigGlobal.page.isHome,
    isSearch: jrConfigGlobal.page.isSearch,
    isJobPreviewPage: jrConfigGlobal.page.isJobPreviewPage,
    pageNum: jrConfigGlobal.search.page.number,
  })
  .value('SubscriptionConfig', {
    buttonAdditionalClass: jrConfigGlobal.subscription.buttonAdditionalClass,
    popupPolicyType: jrConfigGlobal.subscription.popupPolicyType,
    popupMaxCount: jrConfigGlobal.subscription.popupMaxCount,
  })
  .value('MyProfileConfig', {
    featureEnabled: jrConfigGlobal.feature.myProfileEnabled,
    firstname: jrConfigGlobal.myProfile.firstname,
    lastname: jrConfigGlobal.myProfile.lastname,
    gdprJobMarketingAccepted: jrConfigGlobal.myProfile.gdprJobMarketingAccepted,
  })
  .value('ModalConfig', {
    overlayOpacity: jrConfigGlobal.modal.overlayOpacity,
  })
  .value('GoogleOneTapConfig', {
    timeout: jrConfigGlobal.googleOneTap.timeout,
  })
  .constant('CookieClientConstants', {
    name: 'JEC',
    keys: {
      COOKIE_LAW_CONSENT: 'clc',
    },
  })
  .constant('jrEmailConstants', {
    domainSeparator: '@',
    suffixSeparator: '.',
  })
  .constant('isMobile', jrConfigGlobal.isMobile);
