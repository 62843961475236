import { searchFormatter } from '../../ts/utils/formatters.ts';
import hideShowPassword from '../../ts/service/password-service.ts';
import { findProvider } from '../../ts/service/link-to-inbox-service.ts';
import { pushModalEvent, pushEvent } from '../../ts/service/gtm-service.ts';
import { JtagService } from '../../ts/service/jtag-service.ts';
import { getDomain } from '../../ts/service/email-service.ts';
import { loadSocialSubscriptionErrorModal } from '../../ts/component/modal/socialSubscriptionError/loader.ts';
import {
  login,
  updateProfile,
  resetPassword,
  recoverPassword,
  subscribeJobseeker,
} from '../../ts/service/xhr-service.ts';
import { EventConstants, trigger } from '../../ts/service/event-service.ts';
import { isValidDomain } from '../../ts/utils/index.ts';

angular
  .module('jrRegistration', ['jrConfig', 'jrAutocompleteEmail', 'jrUtil', 'jrCommonService'])
  .value('searchFormatter', searchFormatter)
  .value('hideShowPassword', hideShowPassword)
  .value('findProvider', findProvider)
  .value('pushModalEvent', pushModalEvent)
  .value('pushEvent', pushEvent)
  .value('updateProfile', updateProfile)
  .value('isValidDomain', isValidDomain)
  .controller('RegistrationCtrl', RegistrationCtrl);

RegistrationCtrl.$inject = [
  'JobseekerConfig',
  'Messages',
  'AutocompleteEmailConfig',
  'SearchDistanceConfig',
  'SearchFormService',
  '$timeout',
  '$scope',
  'SearchConfig',
  'MyProfileConfig',
  '$rootScope',
  '$window',
  'searchFormatter',
  'hideShowPassword',
  'findProvider',
  'pushModalEvent',
  'pushEvent',
  'updateProfile',
  'isValidDomain',
];

function RegistrationCtrl(
  JobseekerConfig,
  Messages,
  AutocompleteEmailConfig,
  SearchDistanceConfig,
  SearchFormService,
  $timeout,
  $scope,
  SearchConfig,
  MyProfileConfig,
  $rootScope,
  $window,
  searchFormatter,
  hideShowPassword,
  findProvider,
  pushModalEvent,
  pushEvent,
  updateProfile,
  isValidDomain
) {
  $scope.messages = Messages;
  $scope.autocompleteEnabled = AutocompleteEmailConfig.enabled;
  $scope.distanceFilterEnabled = SearchDistanceConfig.enabled;
  $scope.socialLoginEnabled = jrConfigGlobal.socialLogin.enabled;
  $scope.search = SearchFormService.search;
  $scope.isEmptySearch = !$scope.search.what && !$scope.search.where;
  $scope.hasBeenSubmitted = false;
  $scope.jobseekerRegistered = false;
  $scope.resendMail = false;
  $scope.existingJobseeker = false;
  $scope.loginError = false;
  $scope.sendEmailError = false;
  $scope.recoverSuccess = false;
  $scope.setPasswordSuccess = false;
  $scope.redirectUrl = '';
  $scope.registrationConfirmed = JobseekerConfig.registrationConfirmed;
  $scope.captchaEnabled = SearchConfig.isSubscriptionCaptchaEnabled;
  $scope.captchaTest = window.jrConfigGlobal.captcha.isTest;
  $scope.captchaUserResponse = '';
  $scope.resetError = false;
  $scope.errorInDomain = false;
  $scope.isMobile = jrConfigGlobal.isMobile;
  $scope.profileConfig = MyProfileConfig;
  $scope.mySearches = [
    {
      what: $scope.search.what,
      where: $scope.search.where,
    },
    {
      what: undefined,
      where: undefined,
    },
    {
      what: undefined,
      where: undefined,
    },
  ];

  $scope.jobseeker = JobseekerConfig.isLogged ? JobseekerConfig : {};
  $scope.forms = {};
  $scope.searchLabel = searchFormatter({
    what: SearchFormService.search.what,
    where: SearchFormService.search.where,
    rewrittenWhere: SearchFormService.search.rewrittenWhere,
    distance: SearchFormService.search.distance,
  });
  $scope.loading = false;
  $scope.isSocialLogin = false;
  $scope.source = '';
  $scope.tpmConsentShow = window.jrConfigGlobal.feature.gdprThirdPartyMarketingSubscriptionEnabled;

  const registerJobseeker = async () => {
    const response = await subscribeJobseeker({
      email: $scope.jobseeker.email,
      what: $scope.search.what,
      where: $scope.search.where,
      password: $scope.forms.registration.password.$viewValue,
      marketingConsent: $scope.forms.registration.marketingConsent
        ? $scope.forms.registration.marketingConsent.$viewValue
        : '',
      firstName: $scope.forms.registration.firstname
        ? $scope.forms.registration.firstname.$viewValue
        : '',
      lastName: $scope.forms.registration.lastname
        ? $scope.forms.registration.lastname.$viewValue
        : '',
      captchaResponse: $scope.captchaUserResponse,
    });
    handleAfterRegistration(response);
  };

  const registerSocialJobseeker = async () => {
    const response = await subscribeJobseeker({
      email: $scope.jobseeker.email,
      what: $scope.search.what,
      where: $scope.search.where,
      source: $scope.source,
      referrer: 'page',
      firstName: $scope.forms.registration.firstname
        ? $scope.forms.registration.firstname.$viewValue
        : '',
      lastName: $scope.forms.registration.lastname
        ? $scope.forms.registration.lastname.$viewValue
        : '',
      captchaResponse: $scope.captchaUserResponse,
    });

    handleAfterSocialRegistration(response);
  };

  const handleSocialRegistration = async () => {
    if ($scope.captchaTest === false) {
      if (SearchConfig.isSubscriptionCaptchaEnabled && $scope.registrationCaptcha !== -1) {
        grecaptcha.ready(() => {
          grecaptcha.execute($scope.registrationCaptcha);
        });
      } else {
        await registerSocialJobseeker();
      }
    } else {
      await registerSocialJobseeker();
    }
  };

  const handleCaptchaError = () => {
    $scope.loading = false;
    $scope.hasBeenSubmitted = false;
    grecaptcha.reset($scope.registrationCaptcha);
  };

  const handleCaptchaCallback = async (response) => {
    if (response) {
      $scope.captchaUserResponse = response;
      if ($scope.isSocialLogin) {
        await registerSocialJobseeker();
      } else {
        await registerJobseeker();
      }
    } else {
      handleCaptchaError();
    }
  };
  if ($scope.captchaTest === false) {
    if (SearchConfig.isSubscriptionCaptchaEnabled) {
      const interval = setInterval(() => {
        if (window.grecaptcha) {
          grecaptcha.ready(() => {
            $scope.registrationCaptcha = grecaptcha.render('registration-captcha-container', {
              sitekey: jrConfigGlobal.captcha.siteKey,
              size: 'invisible',
              badge: 'inline',
              callback: handleCaptchaCallback,
              'error-callback': handleCaptchaError,
              'expired-callback': handleCaptchaError,
            });
          });
          clearInterval(interval);
        }
      }, 100);
    }
  }

  $scope.inputType = 'password';
  $scope.hideShowPassword = function () {
    $scope.inputType = hideShowPassword($scope.inputType);
  };

  function handleAfterRegistration(result) {
    const statusCreated = result.status >= 200 && result.status < 300;
    const userExist = result.status === 406;
    const statusConflict = result.status === 409;

    pushEvent({
      event: 'jobseekerCreate',
      created: statusCreated,
      subscriberUid: result.headers.get('subscriberUid'),
      subscriptionConversionValue: parseFloat(
        result.headers.get('subscriptionConversionValue') || '0'
      ),
    });
    $scope.loading = false;
    $scope.$apply();
    if (userExist) {
      $scope.existingJobseeker = true;
    }
    if (statusCreated || statusConflict) {
      // Emitting the subscription sent event
      trigger(EventConstants.subscriptionSent, {
        type: 'modal',
        email: $scope.jobseeker.email,
      });

      JtagService.trackSubscription('signup', getDomain($scope.jobseeker.email) || '');

      // Hotjar Tracking
      pushModalEvent({
        event: 'signup.manual',
        modalType: 'signup',
        email: $scope.jobseeker.email,
      });

      if (jrConfigGlobal.feature.confirmationSingleClickEnabled) {
        JtagService.trackConfirmation('single_opt_in');

        let redirectUrl = jrConfigGlobal.baseUrl;

        const emailProviders = findProvider($scope.jobseeker.email);

        if ($scope.search.what || $scope.search.where) {
          const url = [];
          if ($scope.search.what) {
            url.push(`w=${encodeURIComponent($scope.search.what)}`);
          }
          if ($scope.search.where) {
            url.push(`l=${encodeURIComponent($scope.search.where)}`);
          }
          if (jrConfigGlobal.feature.singleOptInWelcomePopupEnabled) {
            url.push(`email=${encodeURIComponent($scope.jobseeker.email)}`);
            if (emailProviders.length) {
              url.push(`linkToInboxProviderUrl=${encodeURIComponent(emailProviders[0].url)}`);
            }
          }
          redirectUrl = `?${url.join('&')}`;
        }

        $window.location.replace(redirectUrl);

        return;
      }

      $scope.jobseekerRegistered = true;
      $scope.existingJobseeker = false;
    } else if (SearchConfig.isSubscriptionCaptchaEnabled && $scope.captchaTest === false) {
      grecaptcha.reset($scope.registrationCaptcha);
    }
  }

  function handleAfterSocialRegistration(result) {
    const statusCreated = result.status >= 200 && result.status < 300;
    const statusConflict = result.status === 409;

    pushEvent({
      event: 'jobseekerCreate',
      created: statusCreated,
      subscriberUid: result.headers.get('subscriberUid'),
      subscriptionConversionValue: parseFloat(
        result.headers.get('subscriptionConversionValue') || '0'
      ),
    });

    $scope.loading = false;

    if (statusCreated || statusConflict) {
      pushEvent({
        event: `signup.${$scope.source}`,
        emailDomain: getDomain($scope.jobseeker.email) || '',
      });
      JtagService.trackSubscription(
        `signup_site_${$scope.source}`,
        getDomain($scope.jobseeker.email) || ''
      );
      $window.location.href = result.headers.get('activateUrl');
    }
  }

  $scope.$watchCollection('search', function (newValue) {
    $scope.mySearches[0] = {
      what: newValue.what,
      where: newValue.where,
    };
    if (typeof $scope.forms.registration !== 'undefined' && !JobseekerConfig.isLogged) {
      $scope.forms.registration.$setValidity('searchParams', !!newValue.what || !!newValue.where);
    }
  });

  $scope.submitRegistration = async function (e) {
    $scope.hasBeenSubmitted = true;

    if (typeof $scope.forms.registration !== 'undefined' && !JobseekerConfig.isLogged) {
      $scope.forms.registration.$setValidity(
        'searchParams',
        !!$scope.search.what || !!$scope.search.where
      );
    }

    if (!$scope.forms.registration.$valid) {
      return;
    }
    $scope.loading = true;
    if (
      !isValidDomain($scope.forms.registration.email.$viewValue) &&
      jrConfigGlobal.feature.emailDomainValidationEnabled
    ) {
      $scope.errorInDomain = true;
      $scope.loading = false;
      return;
    }
    $scope.errorInDomain = false;
    if ($scope.captchaTest === false) {
      if (SearchConfig.isSubscriptionCaptchaEnabled && $scope.registrationCaptcha !== -1) {
        grecaptcha.ready(() => {
          grecaptcha.execute($scope.registrationCaptcha);
        });
      } else {
        await registerJobseeker();
      }
    } else {
      await registerJobseeker();
    }

    e.preventDefault();
  };

  $scope.submitSocialRegistration = async function (e, source) {
    $scope.source = source;
    $scope.hasBeenSubmitted = true;
    $scope.isSocialLogin = true;
    if (typeof $scope.forms.registration !== 'undefined' && !JobseekerConfig.isLogged) {
      $scope.forms.registration.$setValidity(
        'searchParams',
        !!$scope.search.what || !!$scope.search.where
      );
    }
    if ($scope.forms.registration.$error.searchParams) {
      return;
    }

    if ($scope.source === 'facebook') {
      FB.login(
        function (response) {
          if (response.status === 'connected') {
            FB.api(
              '/me',
              {
                fields: 'last_name, first_name, email',
              },
              async function (response) {
                if (typeof response.email === 'undefined') {
                  loadSocialSubscriptionErrorModal();
                } else {
                  $scope.jobseeker.email = response.email;
                  $scope.jobseeker.firstName = response.first_name;
                  $scope.jobseeker.lastName = response.last_name;

                  await handleSocialRegistration();
                }
              }
            );
          }
        },
        {
          scope: 'public_profile, email',
          auth_type: 'rerequest',
        }
      );
    }
    e.preventDefault();
  };

  function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse($window.atob(base64));
  }

  async function handleCredentialResponse(response) {
    $scope.loading = true;
    $scope.source = 'google';
    $scope.hasBeenSubmitted = true;
    $scope.isSocialLogin = true;
    if (typeof $scope.forms.registration !== 'undefined' && !JobseekerConfig.isLogged) {
      $scope.forms.registration.$setValidity(
        'searchParams',
        !!$scope.search.what || !!$scope.search.where
      );
      $scope.$apply();
    }
    if ($scope.forms.registration.$error.searchParams) {
      $scope.loading = false;
      $scope.$apply();
      return;
    }
    const googleAuthRes = parseJwt(response.credential);
    $scope.jobseeker.email = googleAuthRes.email;
    $scope.jobseeker.firstName = googleAuthRes.given_name;
    $scope.jobseeker.lastName = googleAuthRes.family_name;
    await handleSocialRegistration();
    $scope.loading = false;
    $scope.$apply();
  }

  $window.handleCredentialResponse = handleCredentialResponse;

  $scope.recoverPassword = async (e) => {
    e.preventDefault();
    $scope.loading = true;
    $scope.hasBeenSubmitted = true;
    if (!$scope.forms.recovery.$valid) {
      $scope.loading = false;
      return;
    }
    try {
      const response = await recoverPassword($scope.jobseeker.email);
      $scope.loading = false;
      if (response.status === 503) {
        $scope.sendEmailError = true;
        $scope.recoverSuccess = false;
      } else {
        $scope.sendEmailError = false;
        $scope.recoverSuccess = true;
      }
    } catch (e) {
      $scope.sendEmailError = false;
      $scope.recoverSuccess = true;
    }
    $scope.$apply();
  };

  const handleUpdateProfileError = () => {
    $scope.loading = false;
    $scope.resetError = true;
  };

  $scope.updateProfile = async function (e, modalDismiss) {
    $scope.hasBeenSubmitted = true;
    if (!$scope.forms.setPassword.$valid) {
      return;
    }
    $scope.loading = true;

    try {
      const response = await updateProfile({ password: $scope.jobseeker.password });
      if (response.status >= 200 && response.status < 300) {
        $scope.loading = false;
        $rootScope.jobseekerFirstname =
          $scope.jobseeker.firstName || $scope.profileConfig.firstname;
        $rootScope.jobseekerLastname = $scope.jobseeker.lastName || $scope.profileConfig.lastname;

        JtagService.trackCustomEvent('click_confirmation_popup', {
          event_label: 'add_password',
        });

        modalDismiss();
      } else {
        handleUpdateProfileError();
      }
    } catch (e) {
      handleUpdateProfileError();
    }

    e.preventDefault();
  };

  $scope.resetPassword = async function (e) {
    e.preventDefault();
    $scope.hasBeenSubmitted = true;
    if (!$scope.forms.reset.$valid) {
      return;
    }
    $scope.loading = true;

    try {
      const response = await resetPassword({
        password: $scope.forms.reset.password.$viewValue,
        confirmPassword: $scope.forms.reset.confirmPassword.$viewValue,
      });

      if (response.status === 400 || response.status === 500) {
        $scope.loading = false;
        $scope.resetError = true;
      } else {
        $scope.redirectUrl = response.headers.get('x-redirectAfterLogin');
      }
    } catch (e) {
      $scope.loading = false;
      $scope.resetError = true;
    }

    $scope.$apply();
  };

  $scope.redirectTo = function (url) {
    document.location.href = url;
  };

  $scope.submitLogin = async (e) => {
    e.preventDefault();
    $scope.loading = true;
    $scope.hasBeenSubmitted = true;
    if (
      !isValidDomain($scope.forms.login.email.$viewValue) &&
      jrConfigGlobal.feature.emailDomainValidationEnabled
    ) {
      $scope.errorInDomain = true;
      $scope.loading = false;
      return;
    }
    $scope.errorInDomain = false;
    if (!$scope.forms.login.$valid) {
      $scope.loading = false;
      return;
    }
    try {
      const response = await login({
        email: $scope.forms.login.email.$viewValue,
        password: $scope.forms.login['login-password'].$viewValue,
      });

      if (response.status === 406) {
        $scope.loading = false;
        $scope.loginError = true;
      } else {
        $window.location.href = response.headers.get('x-redirectAfterLogin') || document.referrer;
      }
    } catch (e) {
      $scope.loading = false;
      $scope.loginError = true;
    }
    $scope.$apply();
  };

  $scope.reset = function () {
    $scope.resendMail = true;
  };

  $scope.resetLoginError = function () {
    $scope.loginError = false;
  };

  $timeout(function () {
    $scope.loading = false;
  });

  $scope.infoPath = jrConfigGlobal.infoPath;
}
