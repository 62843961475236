angular
  .module('jrWebsiteComponents', [])
  .controller('jrAccordionCtrl', jrAccordionCtrl)
  .directive('jrAnimateFadeIn', jrAnimateFadeIn)
  .directive('jrFooterFixed', jrFooterFixed)
  .directive('jrClearInput', jrClearInput)
  .directive('passwordMatch', passwordMatch);

jrAccordionCtrl.$inject = ['$scope'];
jrAnimateFadeIn.$inject = ['$animate', '$timeout'];
jrFooterFixed.$inject = ['$document', '$window'];
jrClearInput.$inject = ['$parse'];

function jrAccordionCtrl($scope) {
  $scope.menuStatus = [{ isOpen: false }, { isOpen: false }, { isOpen: false }];
  $scope.tabStatus = [{ isOpen: false }, { isOpen: false }, { isOpen: false }];
}

function jrAnimateFadeIn($animate, $timeout) {
  const hidingClass = 'hiding';
  return function (scope, element) {
    element.addClass(`animate-fade-in ${hidingClass}`);
    $timeout(function () {
      $animate.removeClass(element, hidingClass);
    });
  };
}

function jrFooterFixed($document, $window) {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      $document.ready(function () {
        if ($window.innerHeight > element[0].getBoundingClientRect().bottom + 50) {
          element.addClass('fixed');
          document.body.className += ' footer-fixed';
        }
      });
    },
  };
}

function jrClearInput($parse) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link(scope, element, attr) {
      const htmlMarkup = attr.jrClearButtonMarkup
        ? attr.jrClearButtonMarkup
        : '<button type="button"></button>';
      const button = angular.element(htmlMarkup);
      button.addClass(attr.jrClearButtonClass ? attr.jrClearButtonClass : 'clear-button');
      element.after(button);

      button.on('click', function (event) {
        if (attr.jrClearInput) {
          const fn = $parse(attr.jrClearInput);
          scope.$apply(function () {
            fn(scope, {
              $event: event,
            });
          });
        } else {
          const props = attr.ngModel.split('.');
          if (props.length > 1) {
            scope[props[0]][props[1]] = '';
          } else {
            scope[props[0]] = '';
          }
          scope.$digest();
        }
      });

      scope.$watch(attr.ngModel, function (val) {
        const hasValue = val && val.length > 0;
        if (!attr.jrClearDisableVisibility) {
          button.css('visibility', hasValue ? 'visible' : 'hidden');
        }

        if (hasValue && !button.hasClass('clear-visible')) {
          button.removeClass('clear-hidden').addClass('clear-visible');
        } else if (!hasValue && !button.hasClass('clear-hidden')) {
          button.removeClass('clear-visible').addClass('clear-hidden');
        }
      });
    },
  };
}

function passwordMatch() {
  return {
    restrict: 'A',
    scope: true,
    require: 'ngModel',
    link(scope, elem, attrs, control) {
      const checker = function () {
        const e1 = scope.$eval(attrs.ngModel);
        const e2 = scope.$eval(attrs.passwordMatch);
        return e1 === e2;
      };
      scope.$watch(checker, function (n) {
        control.$setValidity('unique', n);
      });
    },
  };
}
