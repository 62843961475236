import { html, TemplateResult } from "lit/html.js";
import { ifDefined } from "lit/directives/if-defined.js"

const LabelComponent = ({
  labelFor,
  cssClasses,
  labelText = ''
}: ILabelElement): TemplateResult => html`
    <label
      for=${ifDefined(labelFor)}
      class=${ifDefined(cssClasses)}
    >
    ${labelText}
    </label>
  `

export default LabelComponent;