import jrMySearchesStatementTemplate from '../../view/angular/pages/my-searches-statement.tpl.html';
import { JtagService } from '../../ts/service/jtag-service.ts';
import { MySearchesResourceService } from '../../ts/service/my-searches-resource-service.ts';

angular
  .module('jrMySearches', ['jrConfig'])
  .value('jrMySearchesStatementTemplate', jrMySearchesStatementTemplate)
  .controller('MySearchesCtrl', [
    'SearchDistanceConfig',
    'SearchOptionsService',
    'MySearchesConfig',
    'MySearchesService',
    '$scope',
    'Messages',
    function (
      SearchDistanceConfig,
      SearchOptionsService,
      MySearchesConfig,
      MySearchesService,
      $scope,
      Messages
    ) {
      $scope.messages = Messages;
      $scope.autoDistanceText = SearchDistanceConfig.autoDistanceText;
      $scope.distanceFilterEnabled = SearchDistanceConfig.enabled;
      $scope.addStatementOpened = false;
      $scope.statementError = false;
      $scope.hasStatements = function () {
        return $scope.statements.length > 0;
      };
      $scope.openAddStatement = function () {
        $scope.addStatementOpened = !$scope.addStatementOpened;
        $scope.statementError = false;
      };
      $scope.addNewStatement = function (event, statement) {
        MySearchesResourceService.addStatement(statement).then(async function (response) {
          const promise = response.json();
          const result = await promise;
          if (result) {
            const { what, where, radiusValue: radius } = result;
            JtagService.trackMySearches('add', { what, where, radius });
            MySearchesService.addStatement(MySearchesService.decorateStatementWithDistance(result));
            $scope.addStatementOpened = false;
            $scope.newStatement = {
              what: '',
              where: '',
              distance: SearchOptionsService.findDistance(),
              distances: SearchOptionsService.distances,
            };
            $scope.$apply();
          } else {
            $scope.statementError = true;
          }
        });
        event.preventDefault();
      };
      $scope.newStatement = {
        what: '',
        where: '',
        distance: SearchOptionsService.findDistance(),
        distances: SearchOptionsService.distances,
      };

      $scope.$watch(
        function () {
          return MySearchesService.statements;
        },
        function (statements) {
          $scope.statements = angular.copy(statements);
          $scope.canAddStatement = $scope.statements.length < MySearchesConfig.maxUserStatements;
        },
        true
      );
    },
  ])
  .directive('jrMySearchesStatement', [
    'MySearchesService',
    'SearchDistanceConfig',
    'jrMySearchesStatementTemplate',
    function (MySearchesService, SearchDistanceConfig, jrMySearchesStatementTemplate) {
      return {
        scope: true,
        restrict: 'AE',
        replace: true,
        template: jrMySearchesStatementTemplate,
        link(scope, element, attrs) {
          scope.isNew = scope.$eval(attrs.isNew) || false;
          scope.isEditing = scope.isNew;
          scope.statementError = false;
          scope.showDistanceInLocation = SearchDistanceConfig.enabled && jrConfigGlobal.isMobile;
          scope.deleteConfirmationVisible = false;
          function isStatementsEqual(newStatement, oldStatement) {
            return (
              newStatement.what === oldStatement.what &&
              newStatement.where === oldStatement.where &&
              newStatement.radiusValue === oldStatement.radiusValue
            );
          }

          scope.addStatement = function (event) {
            event.preventDefault();

            MySearchesResourceService.addStatement(scope.statement).then(async function (response) {
              const promise = response.json();
              const result = await promise;
              MySearchesService.addStatement(
                MySearchesService.decorateStatementWithDistance(result)
              );
              scope.$apply();
            });
          };
          scope.editStatement = function (event) {
            scope.initialStatement = angular.copy(scope.statement);
            scope.isEditing = true;
            scope.focusInput = true;
            scope.statementError = false;
            event.preventDefault();
          };
          scope.resetStatement = function (event) {
            scope.statement = scope.initialStatement;
            scope.isEditing = false;
            event.preventDefault();
          };
          scope.saveStatement = function (event, index) {
            event.preventDefault();

            if (scope.statement.what === '' && scope.statement.where === '') {
              scope.statementError = true;
              event.preventDefault();
              return;
            }
            MySearchesResourceService.saveStatement(scope.statement, index).then(async function (
              response
            ) {
              const promise = response.json();
              const result = await promise;
              const oldStatement = MySearchesService.statements[index];
              const newStatement = result;
              if (!isStatementsEqual(newStatement, oldStatement)) {
                scope.statements = MySearchesService.statements;
                const { what: newWhat, where: newWhere, radiusValue: newRadius } = newStatement;
                const { what: oldWhat, where: oldWhere, radiusValue: oldRadius } = oldStatement;
                JtagService.trackMySearches('add', {
                  what: newWhat,
                  where: newWhere,
                  radiusValue: newRadius,
                });
                JtagService.trackMySearches('delete', {
                  what: oldWhat,
                  where: oldWhere,
                  radiusValue: oldRadius,
                });
                MySearchesService.saveStatement(
                  MySearchesService.decorateStatementWithDistance(newStatement),
                  index
                );
                scope.$apply();
              }
            });
          };
          scope.deleteStatement = function (event, index, redirect = false) {
            event.preventDefault();
            const statement = MySearchesService.statements[index];
            MySearchesResourceService.deleteStatement(index).then(function (response) {
              const { what: oldWhat, where: oldWhere, radiusValue: oldRadius } = statement;
              JtagService.trackMySearches('delete', {
                what: oldWhat,
                where: oldWhere,
                radiusValue: oldRadius,
              });
              if (redirect === true) {
                location.href = event.target.parentElement.href;
              } else {
                MySearchesService.deleteStatement(index);
                scope.statements = MySearchesService.statements;
                scope.$apply();
              }
            });
          };
        },
      };
    },
  ])
  .directive('jrFocusInput', [
    '$timeout',
    function ($timeout) {
      return {
        scope: { trigger: '=jrFocusInput' },
        link(scope, element) {
          scope.$watch('trigger', function (value) {
            if (value) {
              element[0].focus();
              scope.trigger = false;
            }
          });
        },
      };
    },
  ]);
