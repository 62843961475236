import { html, TemplateResult } from "lit/html.js";
import { ifDefined } from "lit/directives/if-defined.js"

const ButtonComponent = ({
  id,
  type,
  cssClasses,
  disable,
  btnText = '',
  eventHandlers
}: IButtonElement): TemplateResult => html`
    <button
    id=${ifDefined(id)}
    type=${type}
    class=${ifDefined(cssClasses)}
    ?disabled=${disable}
    @click=${eventHandlers?.onClick}
    >
    ${btnText}
    </button>
    `

export default ButtonComponent;