import { getDomain } from '../../../ts/service/email-service.ts';
import { JtagService } from '../../../ts/service/jtag-service.ts';

const getLastStatement = (jobseekerData) => {
  const lastStatement =
    jobseekerData.statements && jobseekerData.statements.length > 0
      ? jobseekerData.statements[jobseekerData.statements.length - 1]
      : undefined;

  return lastStatement;
};

const prepareRequest = (url) => {
  const xhr = new XMLHttpRequest();

  xhr.timeout = 4500;
  xhr.open('POST', url, true);
  xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

  return xhr;
};

const createXhrPayload = (jobseekerData) => {
  if (!jobseekerData) {
    return '';
  }

  const payload = ['ConditionAcceptance=on', 'DemAcceptance=true'];
  const lastStatement = getLastStatement(jobseekerData);
  const { captchaResponse = '' } = jobseekerData;
  payload.push(`Email=${jobseekerData.email ? encodeURIComponent(jobseekerData.email) : ''}`);
  if (lastStatement) {
    payload.push(`w=${lastStatement.what ? encodeURIComponent(lastStatement.what) : ''}`);
    payload.push(`l=${lastStatement.where ? encodeURIComponent(lastStatement.where) : ''}`);
    payload.push(
      `r=${
        lastStatement.where && lastStatement.radius ? encodeURIComponent(lastStatement.radius) : ''
      }`
    );
  }

  if (captchaResponse) {
    payload.push(`captchaUserResponse=${captchaResponse}`);
  }

  return payload.join('&');
};

const displayErrorMessage = () => {
  document.querySelector('.js-unsubcribe-error-box').classList.remove('hide');
  document.querySelector('.js-unsubscribe-submit-btn').classList.remove('jr-button--loading');
};

const displaySuccessMessage = () => {
  document.querySelector('.js-unsubscribe-box').classList.add('hide');
  document.querySelector('.js-unsubcribe-success-box').classList.remove('hide');
  document.querySelector('.js-remove-page-message').setAttribute('hidden', 'true');
  document.querySelector('.js-unsubscribe-submit-btn').classList.remove('jr-button--loading');
};

export default function reactivate(unsubscribedJobseekerData) {
  if (!unsubscribedJobseekerData) {
    displayErrorMessage();
    throw new Error(
      `jobseeker data is not available [unsubscribedJobseekerData=${unsubscribedJobseekerData}].`
    );
  }

  const xhr = prepareRequest(`${jrConfigGlobal.baseUrl}jobseeker/create`);
  const xhrPayload = createXhrPayload(unsubscribedJobseekerData);
  xhr.onload = function () {
    if (xhr.status !== 201) {
      displayErrorMessage();
    } else {
      JtagService.trackSubscription(
        'unsubscribe_reactivation',
        getDomain(unsubscribedJobseekerData.email) || ''
      );

      if (jrConfigGlobal.feature.confirmationSingleClickEnabled) {
        const lastStatement = getLastStatement(unsubscribedJobseekerData);

        const redirectUrl = !lastStatement
          ? jrConfigGlobal.baseUrl
          : `${jrConfigGlobal.baseUrl}?w=${
              lastStatement.what ? encodeURIComponent(lastStatement.what) : ''
            }&l=${lastStatement.where ? encodeURIComponent(lastStatement.where) : ''}&r=${
              lastStatement.where && lastStatement.radius
                ? encodeURIComponent(lastStatement.radius)
                : ''
            }`;

        window.location.replace(redirectUrl);
      } else {
        displaySuccessMessage();
      }
    }
  };

  xhr.onerror = function () {
    displayErrorMessage();
  };

  xhr.ontimeout = function () {
    displayErrorMessage();
  };

  xhr.send(xhrPayload);
}
