import { html, TemplateResult } from "lit/html.js";
import { classMap } from "lit/directives/class-map.js";
import { JtagService } from "@services/jtag-service";
import { getFilterUrl, getURLParameter } from "@utils/index";
import { LinkComponent } from "@components/common";

export const SerpFiltersComponent = (messages: IMessages = window.jrConfigGlobal.messages, sortBy = window.jrConfigGlobal.search.sortBy || ''): TemplateResult => {

  JtagService.trackCustomEvent('impression_sortby', {
    event_label: !sortBy ? 'relevance' : sortBy,
  });

  const sortFilters: ISortFilter[] = [
    {
      label: messages.SortFilterByRelevance,
      active: !!(getURLParameter('sortby') === 'relevance' || !getURLParameter('sortby')),
      url: getFilterUrl('sortby', 'relevance'),
      type: 'relevance',
    },
    {
      label: messages.SortFilterByDate,
      active: getURLParameter('sortby') === 'publish_date',
      url: getFilterUrl('sortby', 'publish_date'),
      type: 'publish_date',
    },
  ]

  const handleSortClick = (event: Event, label: string) => {
    event.preventDefault();

    JtagService.trackCustomEvent('click_sortby', {
      event_label: `${label}_filter`,
    });

    window.location.href = (<HTMLAnchorElement>event.target).href;
  };

  return html`
    <span class="serp-filters-title">${messages.SortFilterTitle}</span>
    <ul>
      ${sortFilters.map(({ active, label, url, type }: ISortFilter) => html`
        <li class=${classMap({ active })}>
          ${active ? html`
            <span>${label}</span>
          ` : html` ${LinkComponent({
    rel: 'nofollow',
    href: url,
    target: '_self',
    linkText: label,
    eventHandlers: { onClick: (ev: Event) => handleSortClick(ev, type) }
  })}`}
        </li>
      `)}
    </ul>
   `
}
