import { fetchWithTimeout } from "@libs/fetch";

type Statement = {
  what: string,
  where: string,
  distance?: {
    value: string
  }
}

export class MySearchesResourceService {
  private static baseUrl = `${window.jrConfigGlobal.baseUrl}mysearches`;
  private static addStatementPayload = ({ what, where, distance = { value: '' } }: Statement): string => {
    const url: string[] = [];

    url.push(`edit_w_add=${what}`);
    url.push(`edit_l_add=${where}`);
    url.push(`edit_r_add=${distance.value}`);

    return url.join('&');
  }

  private static saveStatementPayload = ({ what, where, distance = { value: '' } }: Statement, index: number): string => {
    const url: string[] = [];

    url.push(`update_id=${index}`);
    url.push(`edit_w_${index}=${what}`);
    url.push(`edit_l_${index}=${where}`);
    url.push(`edit_r_${index}=${distance.value}`);

    return url.join('&');
  }

  private static deleteStatementPayload = (index: number): string => {
    const url: string[] = [];

    url.push(`edit_count=10`);
    url.push(`delete_id=${index}`);

    return url.join('&');
  }

  public static addStatement = (statement: Statement): Promise<Response> => {
    return fetchWithTimeout(`${MySearchesResourceService.baseUrl}/add`, {
      method: 'POST',
      body: MySearchesResourceService.addStatementPayload(statement),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  }

  public static saveStatement = (statement: Statement, index: number): Promise<Response> => {
    return fetchWithTimeout(`${MySearchesResourceService.baseUrl}/save`, {
      method: 'POST',
      body: MySearchesResourceService.saveStatementPayload(statement, index),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  }

  public static deleteStatement = (index: number): Promise<Response> => {
    return fetchWithTimeout(`${MySearchesResourceService.baseUrl}/delete`, {
      method: 'POST',
      body: MySearchesResourceService.deleteStatementPayload(index),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  }
}