import jrIndeedItemTemplate from '../../view/angular/serp/serp-item/indeed-item.tpl.html';
import jrDfpItemTemplate from '../../view/angular/serp/serp-item/dfp-item.tpl.html';
import jrJobadxItemTemplate from '../../view/angular/serp/serp-item/jobadx-item.tpl.html';

angular
  .module('jrSerpItem', ['jrConfig', 'ipCookie'])
  .service('IndeedService', IndeedService)
  .service('CoursesService', CoursesService)
  .service('JobadxService', JobadxService)
  .controller('SerpItemsCtrl', SerpItemsCtrl)
  .controller('WidgetCoursesTrackingCtrl', WidgetCoursesTrackingCtrl)
  .directive('jrIndeedItem', jrIndeedItem)
  .directive('jrDfpItem', jrDfpItem)
  .directive('jrCoursesItem', jrCoursesItem)
  .directive('jrDfpScript', jrDfpScript)
  .directive('jrJobadxItem', jrJobadxItem);

IndeedService.$inject = ['$http', 'SearchConfig', 'jrGlobalConfig', 'SerpItemsConfig'];
CoursesService.$inject = [
  '$http',
  'WidgetCoursesConfig',
  'SerpItemsConfig',
  'PagesConfig',
  'jrGlobalConfig',
  'SearchConfig',
];
JobadxService.$inject = [
  '$http',
  'SearchConfig',
  'jrGlobalConfig',
  'SerpItemsConfig',
  'JobseekerConfig',
  'ipCookie',
];

SerpItemsCtrl.$inject = [
  '$scope',
  '$rootScope',
  'SerpItemsConfig',
  'IndeedService',
  'WidgetCoursesConfig',
  'CoursesService',
  'JobadxService',
  'SearchConfig',
  '$sce',
];
WidgetCoursesTrackingCtrl.$inject = [
  '$scope',
  'WidgetCoursesConfig',
  'SearchConfig',
  'PagesConfig',
];

jrIndeedItem.$inject = ['SerpItemsConfig'];
jrCoursesItem.$inject = ['$parse', '$compile'];
jrJobadxItem.$inject = ['SerpItemsConfig'];

function SerpItemsCtrl(
  scope,
  $rootScope,
  SerpItemsConfig,
  IndeedService,
  WidgetCoursesConfig,
  CoursesService,
  JobadxService,
  SearchConfig,
  $sce
) {
  scope.indeedAdverts = [];
  scope.jobadxAdverts = [];
  scope.skeleton = {
    courses: true,
    indeed: true,
    jobadx: true,
  };

  scope.viewSalaryEnabled = jrConfigGlobal.feature.viewSalaryEnabled;
  scope.enabled = jrConfigGlobal.feature.displayTotalNumberOfJobsEnabled;
  scope.noOfJobs = jrConfigGlobal.search.totalResults;

  scope.indeedBackfill =
    SerpItemsConfig.itemsEnabled.indeed && SerpItemsConfig.globalParameters.indeed
      ? SerpItemsConfig.globalParameters.indeed.backfill
      : [];
  scope.backfill = false;

  scope.enableIndeedBackfill = function () {
    scope.backfill = true;
  };

  scope.fetchPartnersAdverts = function () {
    if (SerpItemsConfig.itemsEnabled.indeed) {
      IndeedService.fetchAdverts().then(
        function (response) {
          if (response.data.results.length > 0) {
            scope.indeedAdverts = angular.forEach(response.data.results, function (result, key) {
              IndeedService.enrichAdvert(
                result,
                (SerpItemsConfig.globalParameters.indeed &&
                  SerpItemsConfig.globalParameters.indeed.bodyLength) ||
                  120
              );
            });
          }
          scope.skeleton.indeed = false;
        },
        function (error) {
          scope.skeleton.indeed = false;
        }
      );
    }

    if (
      WidgetCoursesConfig.partnerName &&
      SerpItemsConfig.itemsEnabled.courses &&
      WidgetCoursesConfig.url
    ) {
      CoursesService.fetchCourses().then(
        function (response) {
          if (response.data) {
            scope.skeleton.courses = false;

            if (response.data.css) {
              const cssElement = createCssElement(response.data.css);
              document.head.appendChild(cssElement);
            }
            if (response.data.html) {
              scope.widgetCoursesAdverts = $sce.trustAsHtml(response.data.html);
            }
          }
        },
        function (error) {
          scope.skeleton.courses = false;
        }
      );
    }

    function createCssElement(url) {
      const element = document.createElement('link');
      element.rel = 'stylesheet';
      element.href = url;

      return element;
    }

    if (SerpItemsConfig.itemsEnabled.jobadx) {
      if (SearchConfig.what === '') {
        scope.skeleton.jobadx = false;
      } else {
        JobadxService.fetchAdverts().then(
          function (response) {
            if (response.data.data.length > 0) {
              scope.jobadxAdverts = angular.forEach(response.data.data, function (result, key) {
                JobadxService.enrichAdvert(
                  result,
                  (SerpItemsConfig.globalParameters.jobadx &&
                    SerpItemsConfig.globalParameters.jobadx.bodyLength) ||
                    120
                );
              });
            }
            scope.skeleton.jobadx = false;
          },
          function (error) {
            scope.skeleton.jobadx = false;
          }
        );
      }
    }
  };
}

function WidgetCoursesTrackingCtrl($scope, WidgetCoursesConfig, SearchConfig, PagesConfig) {
  const pageSize = getPageSize();
  const { location } = WidgetCoursesConfig;
  const { partnerName } = WidgetCoursesConfig;
  const what = SearchConfig.what ? `/${SearchConfig.what}` : '';
  const gaAdditionalParams = WidgetCoursesConfig.gaAdditionalParams
    ? `/${WidgetCoursesConfig.gaAdditionalParams}`
    : '';

  $scope.trackingCourse = function (event, position) {
    const finalPosition = getItemPosition(position);

    dataLayer.push({
      event: 'widget.courses.item.click',
      widgetCoursesData: `${pageSize}/${finalPosition}/${partnerName}/${location}${what}${gaAdditionalParams}`,
    });
  };

  $scope.trackingSeeAllCourses = function () {
    dataLayer.push({
      event: 'widget.courses.seeAllButton.click',
      widgetCoursesData: `${pageSize}/${partnerName}/${location}${what}${gaAdditionalParams}`,
    });
  };

  $scope.trackingImpressionCourses = function (size) {
    dataLayer.push({
      event: 'widget.courses.impression',
      widgetCoursesData: `${pageSize}/${size}/${partnerName}/${location}${what}${gaAdditionalParams}`,
    });
  };

  function getPageSize() {
    return `page-${PagesConfig.isHome ? '000' : `00${PagesConfig.pageNum}`.slice(-3)}`;
  }

  function getItemPosition(position) {
    return `position-${`0${position}`.slice(-2)}`;
  }
}

function jrIndeedItem(SerpItemsConfig) {
  return {
    restrict: 'A',
    scope: {
      company: '@',
      adverts: '=',
      backfill: '&',
      position: '@',
    },
    template: jrIndeedItemTemplate,
    link(scope) {
      scope.structuredDataEnabled = SerpItemsConfig.structuredDataEnabled;

      scope.indeedClickTracking = function (event) {
        const htmlElement = event.currentTarget;
        const trackingFunction = htmlElement.dataset.tracking;
        const id = /'([^']+)'/g.exec(trackingFunction);

        if (id && typeof indeed_clk === 'function') {
          indeed_clk(htmlElement, id[1]);
        }
      };

      if (scope.adverts.length > 0) {
        scope.indeedAdvert = scope.adverts.shift();
      } else {
        scope.backfill();
      }
    },
  };
}

function jrDfpScript() {
  return {
    restrict: 'A',
    scope: false,
    link(scope) {
      if (scope.itemProperties.slotId) {
        googletag.cmd.push(function () {
          googletag.display(scope.itemProperties.slotId);
        });
      }
    },
  };
}

function jrDfpItem() {
  return {
    restrict: 'A',
    scope: {
      backfillenabled: '=',
      backfillitems: '=',
    },
    template: jrDfpItemTemplate,
    link(scope, element, attr) {
      if (scope.backfillenabled) {
        if (scope.backfillitems.length > 0) {
          const currentItem = scope.backfillitems.shift();

          scope.itemProperties = currentItem;
        }
      }
    },
  };
}

function jrJobadxItem(SerpItemsConfig) {
  return {
    restrict: 'A',
    scope: {
      company: '@',
      adverts: '=',
      position: '@',
    },
    template: jrJobadxItemTemplate,
    link(scope) {
      scope.structuredDataEnabled = SerpItemsConfig.structuredDataEnabled;

      if (scope.adverts.length > 0) {
        scope.jobadxAdvert = scope.adverts.shift();
      }
    },
  };
}

function IndeedService($http, SearchConfig, jrGlobalConfig, SerpItemsConfig) {
  return {
    fetchAdverts() {
      return $http({
        method: 'GET',
        url: 'https://api.jobrapido.com/indeed',
        timeout:
          (SerpItemsConfig.globalParameters.indeed &&
            SerpItemsConfig.globalParameters.indeed.timeout) ||
          1000,
        params: {
          publisher: '943450497272744',
          v: 2,
          userip: jrGlobalConfig.userIp,
          useragent: navigator.userAgent,
          format: 'json',
          co: SearchConfig.country,
          chnl: SearchConfig.country,
          q: SearchConfig.what,
          l: SearchConfig.where,
          radius: SearchConfig.radius.value || 0,
        },
      });
    },
    enrichAdvert(advert, limit) {
      const snippet = advert.snippet.replace(/<[^>]*>/gm, '');

      return angular.extend(advert, {
        snippet:
          snippet.length <= limit
            ? snippet
            : `${snippet.replace(/(\....|\...)$/gm, '').substring(0, limit)}...`,
        data: {
          advertId: advert.jobkey,
          locationsLabel: advert.formattedLocation,
          enumAdvertType: 'AFFILIATION_INDEED',
        },
      });
    },
  };
}

function jrCoursesItem($parse, $compile) {
  return {
    restrict: 'A',
    link(scope, element, attr) {
      const parsed = $parse(attr.ngBindHtml);

      scope.$watch(
        function () {
          return (parsed(scope) || '').toString();
        },
        function () {
          $compile(element, null, -9999)(scope);
        }
      );
    },
  };
}

function CoursesService(
  $http,
  WidgetCoursesConfig,
  SerpItemsConfig,
  PagesConfig,
  jrGlobalConfig,
  SearchConfig
) {
  return {
    fetchCourses() {
      return $http({
        method: 'GET',
        url: `${WidgetCoursesConfig.url}/${WidgetCoursesConfig.partnerName}`,
        timeout:
          (SerpItemsConfig.globalParameters.courses &&
            SerpItemsConfig.globalParameters.courses.timeout) ||
          1000,
        params: {
          lang: jrGlobalConfig.localeLanguage,
          w: SearchConfig.what,
          size: WidgetCoursesConfig.size || 3,
          price: WidgetCoursesConfig.priceEnabled || false,
          page: PagesConfig.pageNum,
        },
      });
    },
  };
}

function JobadxService(
  $http,
  SearchConfig,
  jrGlobalConfig,
  SerpItemsConfig,
  JobseekerConfig,
  ipCookie
) {
  return {
    fetchAdverts() {
      return $http.post(
        'https://api.jobadx.com/v1/exchange/auctions',
        {
          pid: 'eed8e27f-468e-4c19-8e44-701eb17230ae',
          uid: ipCookie('jtagid') || JobseekerConfig.id,
          ip: jrGlobalConfig.userIp,
          useragent: navigator.userAgent,
          keyword: SearchConfig.what,
          location: SearchConfig.where !== '' ? SearchConfig.where : SearchConfig.country,
          slots:
            (SerpItemsConfig.globalParameters.jobadx &&
              SerpItemsConfig.globalParameters.jobadx.slots) ||
            1,
        },
        {
          timeout:
            (SerpItemsConfig.globalParameters.jobadx &&
              SerpItemsConfig.globalParameters.jobadx.timeout) ||
            1000,
        }
      );
    },
    enrichAdvert(advert, limit) {
      const description = advert.description.replace(/<[^>]*>/gm, '');

      return angular.extend(advert, {
        description:
          description.length <= limit
            ? description
            : `${description.replace(/(\....|\...)$/gm, '').substring(0, limit)}...`,
      });
    },
  };
}
