import { JtagService } from "./jtag-service";

export default class TipsService {

  public static init() {
    const tips = document.querySelectorAll('.js-tips');

    tips.forEach((tip: Element) => {
      TipsService.trackTipsImpression(tip);
      tip.querySelector('.js-tip-close')?.addEventListener('click', TipsService.closeTip);

      tip.querySelectorAll('[data-tip-event]').forEach((element) => element.addEventListener('click', TipsService.trackTipClick));
    })
  }

  private static trackTipsImpression = (tip: Element): void => {
    JtagService.trackCustomEvent('tips_impression', {
      event_label:
        (tip.classList.contains('js-empty-what') && 'empty_what') ||
        (tip.classList.contains('js-stop-word-suggestion') && 'empty_what_stop_word') ||
        (tip.classList.contains('js-empty-where') && 'empty_where') ||
        (tip.classList.contains('js-generic-where') && 'generic_where') ||
        (tip.classList.contains('js-broad-suggestions') && 'broad_suggestion') ||
        (tip.classList.contains('js-did-you-mean-tips') && 'did_you_mean') ||
        (tip.classList.contains('js-error-page-tips') && 'zero_results') ||
        '',
    });
  }

  private static closeTip = (event: Event): void => {
    const parentElement = (<HTMLElement>event.currentTarget)?.parentElement;
    if (parentElement) {
      parentElement.classList.add('hide')
    }
  }

  private static trackTipClick = (event: Event): void => {
    const eventToBeTrack = (<HTMLElement>event.currentTarget).dataset.tipEvent || '';
    JtagService.trackCustomEvent('tips_click', {
      event_label: eventToBeTrack
    })
  }
}