import { loadSubscriptionModal } from '../../ts/component/modal/subscriptionProposal/index.ts';
import { JtagService } from '../../ts/service/jtag-service.ts';
import { FavouriteJobsSerpService } from '../../ts/service/favourite-jobs-serp-service.ts';
import { EventConstants, once } from '../../ts/service/event-service.ts';

angular
  .module('jrFavouriteJobsModule', ['jrConfig', 'jrTracking', 'jrCommonService'])
  .controller('FavouriteJobsSerpController', FavouriteJobsSerpController);

FavouriteJobsSerpController.$inject = [
  'SearchConfig',
  'SearchResultsConfig',
  'JobseekerConfig',
  '$scope',
];

function FavouriteJobsSerpController(
  SearchConfig,
  SearchResultsConfig,
  JobseekerConfig,
  $scope,
  $rootScope
) {
  const vm = this;
  vm.adverts = [];
  vm.getFavouriteJobs = getFavouriteJobs;
  vm.updateJob = updateJob;
  vm.isSaved = isSaved;
  vm.isFirst = isFirst;
  vm.isJobseekerLogged = isJobseekerLogged;
  vm.first = '';
  vm.hasResults = false;

  check();
  activate();
  once(EventConstants.singleOptInSubscriptionConfirmed, function () {
    JobseekerConfig.hardLogin = true;
    JobseekerConfig.isLogged = true;
  });

  function activate() {
    return getFavouriteJobs(JobseekerConfig.id, SearchConfig.country);
  }

  function check() {
    return checkJobseekerFavouriteJobs(JobseekerConfig.id, SearchConfig.country);
  }

  async function checkJobseekerFavouriteJobs(userId, country) {
    const hits = await FavouriteJobsSerpService.checkJobseekerFavouriteJobs(userId, country);
    if (hits > 0) {
      vm.hasResults = true;
    } else {
      vm.hasResults = false;
    }
  }

  async function getFavouriteJobs(userId, country) {
    const data = await FavouriteJobsSerpService.getFavouriteJobs(userId, country);
    if (data) {
      vm.adverts = data;
      if (JobseekerConfig.hardLogin || JobseekerConfig.weakLogin) {
        if (vm.adverts.length === 1) {
          setFirst(data[0].advertId);
        } else if (vm.adverts.length === 0) {
          setFirst('');
        }
      }
    }
    $scope.$digest();
  }

  async function updateJob(advertId) {
    if (JobseekerConfig.hardLogin) {
      if (vm.isSaved(advertId)) {
        deleteFavouriteJob(JobseekerConfig.id, advertId);
      } else {
        addFavouriteJob(
          JobseekerConfig.id,
          advertId,
          SearchResultsConfig[advertId].location,
          SearchConfig.country
        );
      }
    } else {
      await loadSubscriptionModal('favourite');
      if (!vm.isSaved(advertId)) {
        addFavouriteJob(
          JobseekerConfig.id,
          advertId,
          SearchResultsConfig[advertId].location,
          SearchConfig.country
        );
      }
    }
  }

  async function addFavouriteJob(userId, advertId, where, country) {
    await FavouriteJobsSerpService.addFavouriteJob(userId, advertId, where, country);
    JtagService.trackFavouriteJobs('add', advertId);
    check();
    getFavouriteJobs(userId, country);
  }

  async function deleteFavouriteJob(jobseekerId, advertId) {
    const jobId = `${jobseekerId}-${advertId}`;
    await FavouriteJobsSerpService.deleteFavouriteJob(jobId);
    JtagService.trackFavouriteJobs('delete', advertId);
    check();
    getFavouriteJobs(JobseekerConfig.id, SearchConfig.country);
  }

  function setFirst(id) {
    vm.first = id;
  }

  function isFirst(id) {
    return id === vm.first;
  }

  function isSaved(id) {
    for (let i = 0; i < vm.adverts.length; i += 1) {
      if (vm.adverts[i].advertId === id) {
        return true;
      }
    }
    return false;
  }

  function isJobseekerLogged() {
    return JobseekerConfig.isLogged;
  }
}
