import PageLoaderService from '../ts/service/page-loader-service.ts';

PageLoaderService.init();

const dependencies = [
  'jrCommonDirective',
  'jrCommonService',
  'jrCommonController',
  'jrAutocompleteEmail',
  'jrCookieConsent',
  'jrFavouriteJobsModule',
  'jrLocationInput',
  'jrRegistration',
  'jrProposal',
  'jrWebsiteComponents',
  'jrSupport',
  'jrMyProfile',
  'jrMySearches',
  'jrSerpItem',
  'ngAnimate',
  'ngSanitize',
  'ui.bootstrap',
];

if (jrConfigGlobal.additionalDependencies.length > 0) {
  jrConfigGlobal.additionalDependencies.forEach((dependency) => {
    dependencies.push(dependency);
  });
}
// AngularJs app configuration
angular.module('jrWebsite', dependencies, [
  '$interpolateProvider',
  function ($interpolateProvider) {
    $interpolateProvider.startSymbol('[[');
    $interpolateProvider.endSymbol(']]');
  },
]);
