import type { TemplateResult } from 'lit/html.js';
import JrModal from '@libs/modal/index';
import SocialSubscriptionErrorModalTemplate from './template';

export default class SocialSubscriptionErrorModal extends JrModal {

  constructor(
    readonly customModalClass: string,
    readonly wrapperClass: string,
  ) {
    super(customModalClass, wrapperClass);
  }

  // eslint-disable-next-line class-methods-use-this
  openedCallback = (): void => { }

  getContent = async (): Promise<TemplateResult> => SocialSubscriptionErrorModalTemplate({
    close: this.close,
    messages: window.jrConfigGlobal.messages
  });
}