import { ButtonComponent } from "@components/common";
import { html, TemplateResult } from "lit/html.js";

const ErrorModalTemplate = ({ messages, close, socialSubscriptionError = false }: IErrorModalTemplateProps): TemplateResult => {
  const {
    JobseekerSubscriptionDialogErrorMessage, JobseekerSubscriptionSocialDialogErrorMessage, JobSeekerConfirmationDialogOkButton } = messages;

  return html`
  <div class="modal-body subscription-error">
    <div class="proposal-claim">
      <p class="description">
        ${socialSubscriptionError ? html`
        <span>${JobseekerSubscriptionSocialDialogErrorMessage}.</span>
        ` : html`
        <span>${JobseekerSubscriptionDialogErrorMessage}.</span>
        `}
      </p>
    </div>
    <div class="row-fluid btn-container">
    ${ButtonComponent({ type: 'button', cssClasses: 'jr-button', btnText: JobSeekerConfirmationDialogOkButton, eventHandlers: { onClick: close } })}
    </div>
  </div>
  `
}

export default ErrorModalTemplate;