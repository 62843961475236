import { addFavouriteJob, checkJobseekerFavouriteJobs, deleteFavouriteJob, getFavouriteJobs } from "./xhr-service";

export class FavouriteJobsSerpService {
  private static jrConfigGlobal: JrConfigGlobal = window.jrConfigGlobal;

  public static getFavouriteJobs = async (jobseekerId: string, countryCode: string): Promise<IFavouriteJob[] | null> => {
    const { searchResults = {} } = FavouriteJobsSerpService.jrConfigGlobal;

    const response = await getFavouriteJobs({ jobseekerId, countryCode, searchResults });
    if (!response) {
      return Promise.resolve(null)
    }
    const favouriteJobs = await response.json();

    return favouriteJobs
  }

  public static checkJobseekerFavouriteJobs = async (jobseekerId: string, countryCode: string): Promise<number | null> => {
    const response = await checkJobseekerFavouriteJobs({ jobseekerId, countryCode });
    if (!response) {
      return Promise.resolve(null)
    }
    const hits = parseInt(response.headers.get('Favourite-Jobs-Total-Hits') || '0', 10);

    return hits
  }

  public static addFavouriteJob = async (jobseekerId: string, advertId: string, where: string, countryCode: string): Promise<IFavouriteJob | null> => {
    const response = await addFavouriteJob({ jobseekerId, advertId, where, countryCode });
    if (!response) {
      return Promise.resolve(null)
    }
    const favouriteJobAdded = await response.json();

    return favouriteJobAdded
  }

  public static deleteFavouriteJob = async (jobId: string): Promise<Response | null> => {
    return deleteFavouriteJob(jobId);
  }
}