import { html, TemplateResult } from "lit/html.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { live } from "lit/directives/live.js";

const InputComponent = ({
  id,
  type,
  name,
  value,
  cssClasses,
  placeholder,
  eventHandlers,
  options
}: IInputElement): TemplateResult => html`
    <input
      id=${ifDefined(id)}
      type=${type}
      name=${ifDefined(name)}
      class=${ifDefined(cssClasses)}
      placeholder=${ifDefined(placeholder)}
      .value=${ifDefined(live(value))}
      tabindex=${ifDefined(options?.tabIndex)}
      autocomplete=${ifDefined(options?.autoComplete)}
      pattern=${ifDefined(options?.pattern)}
      aria-hidden=${ifDefined(options?.ariaHidden)}
      ?autofocus=${options?.autofocus}
      ?disabled=${options?.disabled}
      ?readonly=${options?.readOnly}
      ?required=${options?.required}
      @input=${eventHandlers?.onInput}
      @keydown=${eventHandlers?.onKeyDown}
      @blur=${eventHandlers?.onBlur}
      @change=${eventHandlers?.onChange}
    >
    `

export default InputComponent;