export class SidebarScrollService {
  private static bannerHeight = 0;
  private static proposalMargin = '';
  private static scrollTop = 0;
  private static styleTop = '';
  private static footerOffset = 0;
  private static isWaiting = false;
  private static notFixableOffset = 0;
  private static proposalBoxHeight = 0;
  private static fixedHeaderHeight = 0;
  private static isFooterVisible = false;
  private static footer: HTMLElement | null = null;
  private static proposalBox: HTMLElement | null = null;
  private static detailBox: HTMLElement | null = null;
  private static fixedHeader: HTMLElement | null = null;
  private static gtpMiddleBanner: HTMLElement | null = null;
  private static jrConfigGlobal: JrConfigGlobal = window.jrConfigGlobal;
  private static fixableBanner: HTMLElement | null = document.querySelectorAll('.js-fixable').length ?
    <HTMLElement>document.querySelectorAll('.js-fixable')[0] : null;

  private static setProposalMargin = (top: number, add: number): void => {
    const value = `${top + add}px`
    SidebarScrollService.proposalMargin = value;
    if (SidebarScrollService.proposalBox) {
      SidebarScrollService.proposalBox.style.marginTop = SidebarScrollService.proposalMargin;
    }
  }

  private static setWaiting = (waiting: boolean): void => {
    SidebarScrollService.isWaiting = waiting;
  }

  private static applyBannerStyle = (top: string): void => {
    if (SidebarScrollService.fixableBanner) { SidebarScrollService.fixableBanner.style.top = top; }
    if (SidebarScrollService.proposalBox) { SidebarScrollService.proposalBox.style.top = top; }
  }

  private static setBannerHeight = (height: number): void => {
    SidebarScrollService.bannerHeight = height;
  }

  private static setScrollTop = (scrollTop: number): void => {
    SidebarScrollService.scrollTop = scrollTop;
  }

  private static setFooterOffset = (offset: number): void => {
    SidebarScrollService.footerOffset = offset;
  }

  private static setNotFixableOffset = (offset: number): void => {
    SidebarScrollService.notFixableOffset = offset;
  }

  private static setProposalBoxHeight = (height: number): void => {
    SidebarScrollService.proposalBoxHeight = height;
  }

  private static setFixedHeaderHeight = (height: number): void => {
    SidebarScrollService.fixedHeaderHeight = height;
  }

  private static setFooter = (footer: HTMLElement | null): void => {
    SidebarScrollService.footer = footer;
  }

  private static setFooterVisible = (isFooterVisible: boolean): void => {
    SidebarScrollService.isFooterVisible = isFooterVisible;
  }

  private static setFixedHeader = (header: HTMLElement | null): void => {
    SidebarScrollService.fixedHeader = header;
  }

  private static setFixableBanner = (fixableBanner: HTMLElement): void => {
    SidebarScrollService.fixableBanner = fixableBanner;
  }

  private static setGtpMiddleBanner = (banner: HTMLElement | null): void => {
    SidebarScrollService.gtpMiddleBanner = banner;
  }

  private static setProposalBox = (proposalBox: HTMLElement | null): void => {
    SidebarScrollService.proposalBox = proposalBox;
  }

  private static setDetailBox = (detailBox: HTMLElement | null): void => {
    SidebarScrollService.detailBox = detailBox;
  }

  private static onScroll = (): void => {
    if (SidebarScrollService.isWaiting) return;

    SidebarScrollService.setGtpMiddleBanner(document.getElementById('b_gtp_middle'));
    SidebarScrollService.setFixedHeader(document.querySelector(
      SidebarScrollService.jrConfigGlobal.isDesktop ? 'js-header-bottom' : 'js-mobile-search-box'
    ));
    SidebarScrollService.setFooter(document.querySelector('footer'));
    SidebarScrollService.setProposalBox(document.querySelector('.proposal-box'));
    SidebarScrollService.setDetailBox(document.querySelector('.serp-details'));
    SidebarScrollService.setScrollTop(window.scrollY);
    SidebarScrollService.setProposalBoxHeight(SidebarScrollService.proposalBox?.offsetHeight || 0);
    SidebarScrollService.setFooterOffset(SidebarScrollService.footer?.offsetTop || 0);
    SidebarScrollService.setFooterVisible(SidebarScrollService.footerOffset - SidebarScrollService.scrollTop - 25 < SidebarScrollService.bannerHeight + SidebarScrollService.proposalBoxHeight);
    SidebarScrollService.setFixedHeaderHeight(SidebarScrollService.fixedHeader?.offsetHeight || 0)
    SidebarScrollService.setNotFixableOffset(window.scrollY)

    if (SidebarScrollService.fixableBanner) {
      if (SidebarScrollService.fixableBanner.getBoundingClientRect().top <= SidebarScrollService.fixedHeaderHeight) {
        SidebarScrollService.setNotFixableOffset(window.pageYOffset)
        SidebarScrollService.fixableBanner.classList.add('fixed');
        if (SidebarScrollService.detailBox && window.scrollY > 100){
          SidebarScrollService.detailBox.classList.add('fixed');
        }          
        if (SidebarScrollService.proposalBox && window.scrollY > 100) {
          SidebarScrollService.proposalBox.classList.add('fixed');
          SidebarScrollService.proposalBox.classList.add('transition');
          if (SidebarScrollService.gtpMiddleBanner) { 
            SidebarScrollService.setBannerHeight(SidebarScrollService.gtpMiddleBanner?.offsetHeight || 0);
            const height = SidebarScrollService.gtpMiddleBanner?.offsetHeight;
            SidebarScrollService.setProposalMargin(height, 5);
          }
          
        if (SidebarScrollService.isFooterVisible && SidebarScrollService.footerOffset > 0) {
          SidebarScrollService.applyBannerStyle(SidebarScrollService.styleTop);
        }
      }
      if (SidebarScrollService.notFixableOffset <= SidebarScrollService.fixedHeaderHeight) {
        SidebarScrollService.fixableBanner.classList.remove('fixed');
        SidebarScrollService.proposalBox?.classList.remove('fixed');
        SidebarScrollService.proposalBox?.classList.remove('transition');
        SidebarScrollService.detailBox?.classList.remove('fixed');
        SidebarScrollService.setProposalMargin(0, 0)
      }

    }
    SidebarScrollService.setWaiting(false);
  }
  }

  public static init = (): void => {
    window.addEventListener('scroll', SidebarScrollService.onScroll);
  }

  public static handleTmsWidget = (): void => {
    const banners = document.querySelectorAll('.js-fixable:last-child');
    if (banners.length) {
      SidebarScrollService.setFixableBanner(<HTMLElement>banners[0]);
    }
    SidebarScrollService.init();
  }
}