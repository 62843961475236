import { isMobileSafari } from '@services/browser-detection-service';
import { PolicyType, isSubscriptionPopupVisibile, subscriptionCookieInfo, canShowSubscriptionPopupInJPP } from '@services/subscription-popup-service';
import { createCookie, readCookie } from '@utils/cookie';
import { JtagService } from '@services/jtag-service';

const initModal = async (source = 'auto'): Promise<void> => {
  const { feature: { confirmationSingleClickEnabled } } = window.jrConfigGlobal;
  if (confirmationSingleClickEnabled) {
    const { default: SingleConfirmationProposalModal } = await import("./singleOptIn");
    new SingleConfirmationProposalModal(
      'subscription-modal',
      'jr-modal-wrapper',
      source
    ).init();
  } else {
    const { default: ProposalModal } = await import("./doubleOptIn");
    new ProposalModal(
      'subscription-modal',
      'jr-modal-wrapper',
      source
    ).init();
  }
}

export const loadAutoSubscriptionModal = async (): Promise<void> => {
  const {
    canShowSubscriptionPopup,
    subscription: {
      popupPolicyType
    },
    isMobile,
    page: {
      isJobPreviewPage
    }
  } = window.jrConfigGlobal;

  if (isMobile && isMobileSafari()) {
    const { pageShowListener } = await import("@utils/index");
    pageShowListener(loadAutoSubscriptionModal);
  }

  if (popupPolicyType === PolicyType.NO_POPUP || !canShowSubscriptionPopup) {
    return;
  }

  const cookieValue = readCookie(subscriptionCookieInfo.COOKIE_NAME);

  if (popupPolicyType === PolicyType.AFTER_LANDING && cookieValue === null) {
    createCookie(
      subscriptionCookieInfo.COOKIE_NAME,
      0,
      { days: subscriptionCookieInfo.COOKIE_EXPIRES });

    return;
  }

  if (isJobPreviewPage && !canShowSubscriptionPopupInJPP()) {
    return;
  }

  if (isSubscriptionPopupVisibile(cookieValue ? parseInt(cookieValue, 10) : null)) {
    JtagService.trackSubscriptionProposal('modal_auto');

    await initModal('auto');

    createCookie(
      subscriptionCookieInfo.COOKIE_NAME,
      !cookieValue ? 1 : parseInt(cookieValue, 10) + 1,
      { days: subscriptionCookieInfo.COOKIE_EXPIRES });
  }
}

export const loadSubscriptionModal = async (source: string): Promise<void> => {
  const {
    canShowSubscriptionPopup
  } = window.jrConfigGlobal;

  if (canShowSubscriptionPopup) {
    JtagService.trackSubscriptionProposal(`modal_${source}`);
    await initModal(source)
  }
}